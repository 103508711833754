'use client';

import { useState } from 'react';
import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import { request } from '@/services/request';
import { toast } from 'sonner';
import { AppGraph, Component } from 'types/api-data';
import { IAppData, IComponentData } from 'types/new-app-d';

import { iconMap } from '@/lib/helper';

import { CustomButton } from '../common';
import { System } from '../icons';
import { AssignableComp } from '../new-app-page/components/AssignableComp';
import { FullScreenSpinner } from '../spinner/full-screen-spinner';

export const ComponentDisplayItems = ({
  data,
  onRefresh,
  appDetails,
  addedComponent,
  setAddedComponent,
  setAppDetails,
}: {
  data?: AppGraph;
  onRefresh: () => void;
  appDetails: IAppData;
  addedComponent: IComponentData[];
  setAddedComponent: React.Dispatch<React.SetStateAction<IComponentData[]>>;
  setAppDetails: (value: IAppData) => void;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const { messages } = useLanguage();
  const langData = messages?.ApplicationDetailPage;

  const handleAddComponent = async (selectedComponent: IComponentData[]) => {
    try {
      setIsLoading(true);

      const body = {
        ...appDetails,
        components: [...appDetails.components, ...selectedComponent],
      };

      const response = await request.put(
        { endpoint: `apps/${data?.name}` },
        body
      );

      setAppDetails(response[0]);
      toast.success(langData?.component_added_successfully);
      onRefresh();
    } catch (error) {
      toast.error(langData?.error_in_adding_component);
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const dataTransform = (data: AppGraph) => {
    const componentsData = data.components.filter(
      (item: Component) => item.domain === 'logical'
    );

    return componentsData ?? [];
  };

  return (
    <div className="w-full">
      {isLoading && <FullScreenSpinner text="Adding Component" />}
      <h3 className="pb-3 text-h3 font-medium text-body-subtle">
        {messages?.General?.components}
      </h3>
      <div className="rounded-lg border border-line-secondary bg-canvas-background-primary px-2 py-3">
        {data && dataTransform(data)?.length > 0 ? (
          <>
            <div className="text-body-primary">
              {data &&
                dataTransform(data).map((item: Component) => (
                  <div
                    className="flex items-center gap-2.5 p-2.5"
                    key={item.display_name}
                  >
                    <span className="flex size-8 items-center justify-center rounded-full bg-white text-black">
                      <Image
                        className="size-5"
                        src={iconMap[item.type] ?? iconMap['default']}
                        alt="Logo"
                        width={100}
                        height={100}
                      />
                    </span>
                    <div className="flex flex-col">
                      <span className="text-body-sm font-normal text-link-active">
                        {item.type}
                      </span>
                      <h4 className="text-h4 font-medium">
                        {item.display_name}
                      </h4>
                    </div>
                  </div>
                ))}
            </div>
            {/* TODO: border-white -> okahu color */}
            <div className="p-2.5">
              <CustomButton
                customSize="regular"
                customVariant="secondary"
                onClick={() => setDialogOpen(true)}
              >
                {langData?.add_components}
              </CustomButton>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center rounded-md p-4 text-white">
            {System.InfoIcon('size-6 text-form-input-disabled')}
            <p className="mb-3 mt-1 text-center text-xs">
              {langData?.you_dont_have_any_components_added}
            </p>
            <CustomButton
              type="button"
              customSize="regular"
              customVariant="secondary"
              onClick={() => setDialogOpen(true)}
            >
              {langData?.add_components}
            </CustomButton>
          </div>
        )}
      </div>
      {dialogOpen && (
        <AssignableComp
          open={dialogOpen}
          setOpen={setDialogOpen}
          addedComponent={addedComponent}
          setAddedComponent={setAddedComponent}
          addComponentFromDetail={handleAddComponent}
        />
      )}
    </div>
  );
};
