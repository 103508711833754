/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useEffect } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useKPIContext } from '@/providers/KpiProvider';
import { ALL_TIME_START_DATE } from '@/utils/constants';
import { convertToUTCDate } from '@/utils/convertToUTCDate';

import { DateRange } from '../display-page/prompts/date-range-picker/calendar-helper';

const convertDateToUTC = (date: Date, isEndOfDay: boolean): Date => {
  const utcString = convertToUTCDate(date, isEndOfDay);
  return new Date(utcString!);
};

const formatDateForURL = (date: Date): string => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
};

const useKPI = (appName: string) => {
  const { data, loading, fetchKPI } = useKPIContext();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  const KPIData = data ? data[appName] : undefined;

  const handleDateRangeApply = (values: { range: DateRange }) => {
    const { range } = values;

    if (!range.from && !range.to) {
      const startTime = new Date(ALL_TIME_START_DATE);
      const endTime = new Date();

      const params = new URLSearchParams(searchParams);
      params.set('days', 'all');
      params.delete('start');
      params.delete('end');
      router.replace(`${pathname}?${params.toString()}`, { scroll: false });
      const apiRange = {
        from: convertDateToUTC(startTime, false),
        to: convertDateToUTC(endTime, true),
      };

      fetchKPI(appName, apiRange);
    } else if (range.from && range.to) {
      const params = new URLSearchParams(searchParams);
      params.delete('days');
      params.set('start', formatDateForURL(range.from));
      params.set('end', formatDateForURL(range.to));
      router.replace(`${pathname}?${params.toString()}`, { scroll: false });
      const apiRange = {
        from: convertDateToUTC(range.from, false),
        to: convertDateToUTC(range.to, true),
      };

      fetchKPI(appName, apiRange);
    }
  };

  useEffect(() => {
    const daysParam = searchParams.get('days');
    const startParam = searchParams.get('start');
    const endParam = searchParams.get('end');

    if (daysParam === 'all') {
      const today = new Date();

      const allTimeStart = new Date(ALL_TIME_START_DATE);
      const endOfDay = new Date(today);
      endOfDay.setUTCHours(23, 59, 59, 999);

      const apiRange = {
        from: allTimeStart,
        to: endOfDay,
      };

      fetchKPI(appName, apiRange);
    } else if (startParam && endParam) {
      const apiRange = {
        from: convertDateToUTC(new Date(startParam), false),
        to: convertDateToUTC(new Date(endParam), true),
      };

      fetchKPI(appName, apiRange);
    } else {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
      const now = new Date();

      const apiRange = {
        from: convertDateToUTC(sevenDaysAgo, false),
        to: convertDateToUTC(now, true),
      };

      fetchKPI(appName, apiRange);
    }
  }, [appName, fetchKPI, searchParams]);

  return { KPIData, loading, handleDateRangeApply };
};

export default useKPI;
