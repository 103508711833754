/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/

import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import { formatDurationText } from '@/utils/formatDurationText';
import { format, parseISO } from 'date-fns';
import { IPrompt, PromptsData } from 'types/prompt';

import {
  formatPromptInput,
  formatPromptOutput,
  formatSecondsAndMilliseconds,
  iconMap,
} from '@/lib/helper';
import { cn } from '@/lib/utils';

import { DialogContent, DialogDescription, DialogTitle } from '../ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { DisplayGanttChart } from './display-chart';
import { ComparePrompts } from './prompt-compare';
import PromptDetailsFooter from './prompt-details-modal-footer';

export const PromptDetailsModal = ({
  position,
  data,
  compare,
  handlePrev,
  handleNext,
}: {
  position?: number;
  data: PromptsData;
  compare?: boolean;
  handlePrev?: () => void;
  handleNext?: () => void;
}) => {
  const { messages } = useLanguage();
  const headerItems = messages?.General;
  const rowHeader = [
    headerItems?.input,
    headerItems?.output,
    headerItems?.model,
    headerItems?.service,
    headerItems?.provider,
    messages?.PromptsListing?.row_header?.tokens,
    headerItems?.duration,
  ];

  const findItem = (rowHeader: string, prompt: IPrompt) => {
    let value;
    let start;
    let end;
    let duration;
    switch (rowHeader) {
      case headerItems?.input:
        value = formatPromptInput(prompt) ?? '';
        break;
      case headerItems?.output:
        value = formatPromptOutput(prompt) ?? '';
        break;
      case headerItems?.model:
        value = prompt?.model_name;
        break;
      case headerItems?.service:
        value = prompt?.model_attributes?.service_name;
        break;
      case headerItems?.provider:
        value = prompt?.model_attributes?.provider_name;
        break;
      case messages?.PromptsListing?.row_header?.tokens:
        value = prompt?.model_attributes?.total_tokens;
        break;
      case headerItems?.duration: {
        start = new Date(prompt.start_time).valueOf();
        end = new Date(prompt.end_time).valueOf();
        duration = formatSecondsAndMilliseconds(end - start);

        const parsedStartDate = parseISO(prompt.start_time);
        const formattedStartDate = format(
          parsedStartDate,
          'dd MMM h:mm:ss.SSSa'
        );
        const parsedEndDate = parseISO(prompt.end_time);
        const formattedEndDate = format(parsedEndDate, 'dd MMM h:mm:ss.SSSa');

        value = `${duration} (${formattedStartDate} - ${formattedEndDate})`;
        break;
      }

      default:
        value = 'N/A';
    }
    return value;
  };

  return (
    <DialogContent
      className="max-h-[80%] max-w-[960px] gap-0 border-link-active bg-gradient-sky-dark p-[30px]"
      onInteractOutside={(e) => {
        e.preventDefault();
      }}
    >
      <DialogTitle className={`text-[24px] font-normal leading-7`}>
        {compare
          ? `${messages?.PromptsListing.compare}`
          : messages?.PromptsListing?.row_header?.prompt_details}
      </DialogTitle>
      <DialogDescription
        asChild
        className="mt-4 border-b border-line-primary pb-2.5"
      >
        <Tabs defaultValue="prompts">
          {!compare && (
            <TabsList className="flex w-full justify-start gap-8 rounded-none border-b border-line-primary bg-transparent p-0">
              <TabsTrigger
                value="prompts"
                className="rounded-none border-b-2 border-b-transparent px-0 pb-3 text-h4 font-medium text-link-active hover:border-b-link-active data-[state=active]:border-b-body-primary data-[state=active]:bg-transparent data-[state=active]:text-body-primary"
              >
                {messages?.General?.prompts}
              </TabsTrigger>
              <TabsTrigger
                value="traces"
                className="rounded-none border-b-2 border-b-transparent px-0 pb-3 text-h4 font-medium text-link-active hover:border-b-link-active data-[state=active]:border-b-body-primary data-[state=active]:bg-transparent data-[state=active]:text-body-primary"
              >
                {messages?.General?.traces}
              </TabsTrigger>
            </TabsList>
          )}
          <TabsContent
            value="prompts"
            className={`max-h-[410px] overflow-auto rounded-sm ${
              compare
                ? 'border-t border-t-line-primary pt-3'
                : 'mt-2.5 border border-line-secondary'
            }`}
          >
            {compare ? (
              <ComparePrompts
                data={data}
                rowHeader={rowHeader}
                findItem={findItem}
              />
            ) : (
              rowHeader.map((item, index) => (
                <div key={item} className="flex ">
                  <div
                    className={cn(
                      'w-[200px] min-w-36 bg-[#093242] p-3 text-body-subtle',
                      item === headerItems?.input && 'rounded-tl-sm',
                      item ===
                        messages?.PromptsListing?.row_header?.prompt_details &&
                        'rounded-bl-sm',
                      index !== 0 &&
                        'border border-x-0 border-b-0 border-t-line-secondary'
                    )}
                  >
                    {item}
                  </div>
                  <div
                    className={cn(
                      'flex w-full items-center gap-2 bg-gradient-sky-dark p-3 text-body-primary',
                      item === headerItems?.input && 'rounded-tr-sm',
                      item ===
                        messages?.PromptsListing?.row_header?.prompt_details &&
                        'rounded-br-sm',
                      index !== 0 &&
                        'border border-x-0 border-b-0 border-t-line-secondary'
                    )}
                  >
                    {item === headerItems?.provider &&
                    findItem(item!, data[position!]) ? (
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                        <Image
                          className="size-6"
                          src={
                            iconMap[
                              data[position!]?.model_attributes?.provider_type
                            ] ?? iconMap['default']
                          }
                          alt={`${
                            data[position!]?.model_attributes?.provider_type ??
                            'default'
                          } provider`}
                          width={100}
                          height={100}
                        />
                      </div>
                    ) : item === headerItems?.service &&
                      findItem(item!, data[position!]) ? (
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                        <Image
                          className="size-6"
                          src={
                            iconMap[
                              data[position!]?.model_attributes?.service_type
                            ] ?? iconMap['default']
                          }
                          alt={`${
                            data[position!]?.model_attributes?.service_type ??
                            'default'
                          } service`}
                          width={100}
                          height={100}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {item === `${messages?.General?.duration}`
                      ? formatDurationText(
                          findItem(item, data[position!]) as string
                        )
                      : findItem(item!, data[position!])}{' '}
                  </div>
                </div>
              ))
            )}
          </TabsContent>
          <TabsContent value="traces">
            {position !== undefined && (
              <DisplayGanttChart
                trace_id={data[position].trace_id}
                workflow_name={data[position].workflow_name}
              />
            )}
          </TabsContent>
        </Tabs>
      </DialogDescription>
      <PromptDetailsFooter
        compare={compare}
        position={position}
        dataLength={data.length}
        onPrev={handlePrev}
        onNext={handleNext}
      />
    </DialogContent>
  );
};
